<template>
  <v-card :dark="$dark.get()" min-height="90vh">
    <v-card-title class="header"> Статистика всех заказов </v-card-title>
    <loader v-if="loading"></loader>
    <v-simple-table v-else fixed-header class="orders_wrapper" width="100%">
      <thead>
        <tr>
          <th class="orders_head">Филиал</th>
          <th class="orders_head">Время доставки крайнего заказа</th>
          <th class="orders_head">Выполненные вовремя</th>
          <th class="orders_head">С опозданием</th>
          <th class="orders_head">Кол-во заказов</th>
          <th class="orders_head">Кол-во сборщиков (открытые смены)</th>
          <th class="orders_head">Кол-во курьеров (открытые смены)</th>
          <th class="orders_head">Кол-во курьеров (зоновские)</th>
          <th class="orders_head">Новые</th>
          <th class="orders_head">В обработке</th>
          <th class="orders_head">Готов к согласованию</th>
          <th class="orders_head">Согласован</th>
          <th class="orders_head">На кассе</th>
          <th class="orders_head">Отгружен</th>
          <th class="orders_head">Доставляется</th>
          <th class="orders_head">Доставлен</th>
          <th class="orders_head">Отменен</th>
          <th class="orders_head">Возврат</th>
          <th class="orders_head">Клиент недоступен</th>
          <th class="orders_head">В ожидании оплаты</th>
          <th class="orders_head">Готов к сборке</th>
        </tr>
      </thead>
      <!-- Блок заказов -->
      <tbody>
        <tr class="orders_row">
          <td class="orders_checkbox">Всего</td>
          <td class="orders_item">-</td>
          <td class="orders_item">{{ STATISTICS.totalOnTimeDeliveries }}</td>
          <td class="orders_item">{{ STATISTICS.totalLateDeliveries }}</td>
          <td class="orders_item">{{ STATISTICS.totalOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalPickersCount }}</td>
          <td class="orders_item">{{ STATISTICS.totalCouriersCount }}</td>
          <td class="orders_item">{{ STATISTICS.totalCouriersZonesCount }}</td>
          <td class="orders_item">{{ STATISTICS.totalNewOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalOrdersInProcess }}</td>
          <td class="orders_item">{{ STATISTICS.totalReadyToAgreeOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalAgreementOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalOrdersAtCashDesk }}</td>
          <td class="orders_item">{{ STATISTICS.totalOrdersShipped }}</td>
          <td class="orders_item">{{ STATISTICS.totalOrdersOnDelivery }}</td>
          <td class="orders_item">{{ STATISTICS.totalDeliveredOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalCancelledOrders }}</td>
          <td class="orders_item">{{ STATISTICS.totalReturnedOrders }}</td>
          <td class="orders_item">
            {{ STATISTICS.totalDepartmentAwaitingOrders }}
          </td>
          <td class="orders_item">
            {{ STATISTICS.totalWaitingForPaymentOrders }}
          </td>
          <td class="orders_item">
            {{ STATISTICS.totalReadyToAssembleOrders }}
          </td>
        </tr>
        <tr
          v-for="item in STATISTICS.orderStats"
          :key="item.id"
          class="orders_row"
        >
          <td class="orders_checkbox">{{ item.department }}</td>
          <td class="orders_item">{{ item.lastDeliveryTime }}</td>
          <td class="orders_item">{{ item.onTimeDeliveries }}</td>
          <td class="orders_item">{{ item.lateDeliveries }}</td>
          <td class="orders_item">{{ item.totalOrders }}</td>
          <td class="orders_item">{{ item.pickersCount }}</td>
          <td class="orders_item">{{ item.couriersCount }}</td>
          <td class="orders_item">{{ item.couriersZonesCount }}</td>
          <td class="orders_item">{{ item.newOrdersCount }}</td>
          <td class="orders_item">{{ item.ordersInProcess }}</td>
          <td class="orders_item">{{ item.readyToAgreeOrders }}</td>
          <td class="orders_item">{{ item.agreementOrders }}</td>
          <td class="orders_item">{{ item.ordersAtCashDesk }}</td>
          <td class="orders_item">{{ item.ordersShipped }}</td>
          <td class="orders_item">{{ item.ordersOnDelivery }}</td>
          <td class="orders_item">{{ item.deliveredOrders }}</td>
          <td class="orders_item">{{ item.cancelledOrders }}</td>
          <td class="orders_item">{{ item.returnedOrders }}</td>
          <td class="orders_item">{{ item.departmentAwaitingOrders }}</td>
          <td class="orders_item">{{ item.waitingForPaymentOrders }}</td>
          <td class="orders_item">{{ item.readyToAssembleOrders }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Order/Loader";
export default {
  components: { Loader },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      STATISTICS: "Order/STATISTICS",
    }),
  },
  mounted() {
    this.setData();
  },
  methods: {
    ...mapActions({
      GET_ORDERS_STATISTICS: "Order/GET_ORDERS_STATISTICS",
    }),
    async setData() {
      this.loading = true;
      await this.GET_ORDERS_STATISTICS(this.$root.city);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.orders {
  &_wrapper {
    box-sizing: border-box;
    // разрыв между строками таблицы
    .v-data-table__wrapper table {
      border-spacing: 0 5px;
    }
  }
  &_head {
    background-color: #5d4bd0 !important;
    color: white !important;
    padding: 0 3px !important;
    &:first-child {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }
    &:last-child {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  &_row {
    background-color: #d9d9d9;
  }
  &_item {
    padding: 10px 3px !important;
    &:last-child {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }
    &:nth-child(even) {
      background-color: #b5b3b3;
    }
    &:nth-child(2) {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      padding-left: 15px !important;
    }
  }
  &_checkbox {
    padding: 0 0 0 7px !important;
    width: 20px;
    background-color: white;
  }
}
</style>
